import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from '@Config/authConfig'
import theme from '@Config/theme'
import { datadogRum } from '@datadog/browser-rum'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import i18n from './Config/i18n'
import queryClient from './Config/queryClientProvider'
import reportWebVitals from './reportWebVitals'
import { getCspNonce } from '@Libs/utilities'
/**
 * @summary : DataDog integration
 */

datadogRum.init({
	applicationId: '6b5350d5-f750-470a-890f-8ce1faef4bf6',
	clientToken: 'pub38d8a23f75133a48e268789defb3d0ee',
	site: 'datadoghq.com',
	service: 'digikm---itop',
	env: process.env.NODE_ENV || 'production',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 100,
	startSessionReplayRecordingManually: true,
	enableExperimentalFeatures: ['feature_flags'],
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowFallbackToLocalStorage: true,
	trackSessionAcrossSubdomains: true,
	allowUntrustedEvents: true,
})

export const msalInstance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const emotionCache = createCache({
	key: 'mui',
	prepend: true,
	nonce: getCspNonce(),
})
msalInstance.initialize().then(() => {
	root.render(
		<React.StrictMode>
			<CacheProvider value={emotionCache}>
				<ThemeProvider theme={theme('light')}>
					<CssBaseline />
					<MsalProvider instance={msalInstance}>
						<I18nextProvider i18n={i18n}>
							<QueryClientProvider client={queryClient}>
								<BrowserRouter>
									<App />
								</BrowserRouter>
								<ReactQueryDevtools initialIsOpen={false} />
							</QueryClientProvider>
						</I18nextProvider>
					</MsalProvider>
				</ThemeProvider>
			</CacheProvider>
		</React.StrictMode>
	)
})

reportWebVitals()
