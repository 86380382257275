export enum FILE_UPLOAD_STATUS {
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
}
export const FILE_UPLOAD_MAX_SIZE = 30
export const FILE_UPLOAD_MAX_SIZE_UNIT = 'MB'
export const FILE_UPLOAD_BUTTON_TOOLTIP = `Attach PDF, TXT or DOCX files. Max ${FILE_UPLOAD_MAX_SIZE}${FILE_UPLOAD_MAX_SIZE_UNIT} each.`
export const FILE_UPLOAD_ALLOWED_TYPES =
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/pdf'
