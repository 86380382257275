import { FETCH_USER_METADATA } from '@Constants/query-keys'
import { getUserProfile } from '@Requests/graph.api'
import { useQuery } from '@tanstack/react-query'
export const useGraphUserMetadata = (token: string | null) => {
	return useQuery({
		queryKey: [FETCH_USER_METADATA],
		queryFn: async () => {
			if (!token) throw new Error('No token available')
			return await getUserProfile(token)
		},
		enabled: !!token,
		select: ({ data }) => {
			const base64 = btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
			const photoBase64 = `data:image/jpeg;base64,${base64}`
			return photoBase64
		},
	})
}
