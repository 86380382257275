import { T_BUTTON_COLOR } from '@Constants/commons'
import { buttonHasText } from '@Libs/utilities'
import type { PaletteColor, Theme, ThemeOptions } from '@mui/material/styles'
import { alpha, darken, responsiveFontSizes } from '@mui/material/styles'
import { PaletteOptions } from '@mui/material/styles/createPalette'
import createTheme from '@mui/material/styles/createTheme'

const PRIMARY_BASE_COLOR = '#0088c7'
const SECONDARY_BASE_COLOR = '#8ec63f'
const GREY_BASE_COLOR = '#eceef0'
const DEFAULT_BASE_MAIN = '#242d34'

interface CustomPaletteColor extends PaletteColor {
	10?: string
	20?: string
	30?: string
	40?: string
	50?: string
	60?: string
	70?: string
	80?: string
	90?: string
	100?: string
}

interface CustomPaletteOptions extends PaletteOptions {
	primary: CustomPaletteColor
	secondary: CustomPaletteColor
	error: CustomPaletteColor
	default: CustomPaletteColor
}
declare module '@mui/material/styles/createPalette' {
	interface PaletteColor {
		10?: string
		20?: string
		30?: string
		40?: string
		50?: string
		60?: string
		70?: string
		80?: string
		90?: string
		100?: string
	}
	interface CommonColors {
		darkGrey: string
		grayscaleBase: string
		green: string
		red: string
		blue: string
		black: string
		app_gradient: string
	}

	interface TypeBackground {
		primaryGradient: string
		blueGradient: string
		lightGreyGradient: string
	}
}

declare module '@mui/material/styles/createTypography' {
	interface Typography {
		h1Bold?: TypographyStyleOptions
		h2Bold?: TypographyStyleOptions
		h3Bold?: TypographyStyleOptions
		h4Bold?: TypographyStyleOptions
		h5Bold?: TypographyStyleOptions
		h6Bold?: TypographyStyleOptions
		subtitle1Bold?: TypographyStyleOptions
		subtitle2Bold?: TypographyStyleOptions
		subtitle3?: TypographyStyleOptions
		subtitle3Bold?: TypographyStyleOptions
	}

	interface TypographyOptions {
		h1Bold?: TypographyStyleOptions
		h2Bold?: TypographyStyleOptions
		h3Bold?: TypographyStyleOptions
		h4Bold?: TypographyStyleOptions
		h5Bold?: TypographyStyleOptions
		h6Bold?: TypographyStyleOptions
		subtitle1Bold?: TypographyStyleOptions
		subtitle2Bold?: TypographyStyleOptions
		subtitle3?: TypographyStyleOptions
		subtitle3Bold?: TypographyStyleOptions
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h1Bold: true
		h2Bold: true
		h3Bold: true
		h4Bold: true
		h5Bold: true
		h6Bold: true
		subtitle1Bold: true
		subtitle2Bold: true
		subtitle3: true
		subtitle3Bold: true
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		default: true
	}
}

const typography: ThemeOptions['typography'] = {
	// Headings - Regular
	h1: {
		fontFamily: 'IdealSans',
		fontSize: '3rem', // 48px equivalent
		fontWeight: 400, // Regular
		'@media (min-width:600px)': {
			fontSize: '3rem', // 48px on larger screens
		},
	},
	h2: {
		fontFamily: 'IdealSans',
		fontSize: '2.5rem', // 40px equivalent
		fontWeight: 400, // Regular
		'@media (min-width:600px)': {
			fontSize: '2.5rem', // 40px on larger screens
		},
	},
	h3: {
		fontFamily: 'IdealSans',
		fontSize: '2rem', // 32px equivalent
		fontWeight: 400, // Regular
		'@media (min-width:600px)': {
			fontSize: '2rem', // 32px on larger screens
		},
	},
	h4: {
		fontFamily: 'IdealSans',
		fontSize: '1.5rem', // 24px equivalent
		fontWeight: 400, // Regular
	},
	h5: {
		fontFamily: 'IdealSans',
		fontSize: '1.125rem', // 18px equivalent
		fontWeight: 400, // Regular
		'@media (min-width:600px)': {
			fontSize: '1.125rem', // 18px on larger screens
		},
	},
	h6: {
		fontFamily: 'IdealSans',
		fontSize: '0.875rem', // 14px equivalent
		fontWeight: 400, // Regular
		'@media (min-width:600px)': {
			fontSize: '0.875rem', // 14px on larger screens
		},
	},

	// Headings - Bold
	h1Bold: {
		fontFamily: 'IdealSans',
		fontSize: '3rem', // 48px equivalent
		fontWeight: 500, // Bold
		'@media (min-width:600px)': {
			fontSize: '3rem', // 48px on larger screens
		},
	},
	h2Bold: {
		fontFamily: 'IdealSans',
		fontSize: '2.5rem', // 40px equivalent
		fontWeight: 500, // Bold
		'@media (min-width:600px)': {
			fontSize: '2.5rem', // 40px on larger screens
		},
	},
	h3Bold: {
		fontFamily: 'IdealSans',
		fontSize: '2rem', // 32px equivalent
		fontWeight: 500, // Bold
		'@media (min-width:600px)': {
			fontSize: '2rem', // 32px on larger screens
		},
	},
	h4Bold: {
		fontFamily: 'IdealSans',
		fontSize: '1.5rem', // 24px equivalent
		fontWeight: 500, // Bold
	},
	h5Bold: {
		fontFamily: 'IdealSans',
		fontSize: '1.125rem', // 18px equivalent
		fontWeight: 500, // Bold
		'@media (min-width:600px)': {
			fontSize: '1.125rem', // 18px on larger screens
		},
	},
	h6Bold: {
		fontFamily: 'IdealSans',
		fontSize: '0.875rem', // 14px equivalent
		fontWeight: 500, // Bold
		'@media (min-width:600px)': {
			fontSize: '0.875rem', // 14px on larger screens
		},
	},
	subtitle1: {
		fontSize: '1rem', // 16px
		fontWeight: 'normal',
		fontFamily: 'Roboto',
	},
	subtitle1Bold: {
		fontSize: '1rem', // 16px
		fontWeight: 'bold',
		fontFamily: 'Roboto',
	},
	subtitle2: {
		fontSize: '0.875rem', // 14px
		fontWeight: 'normal',
		fontFamily: 'Roboto',
	},
	subtitle2Bold: {
		fontSize: '0.875rem', // 14px
		fontWeight: 'bold',
		fontFamily: 'Roboto',
	},
	subtitle3: {
		fontSize: '0.75rem', // 12px
		fontWeight: 'normal',
		fontFamily: 'Roboto',
	},
	subtitle3Bold: {
		fontSize: '0.75rem', // 12px
		fontWeight: 'bold',
		fontFamily: 'Roboto',
	},
	body1: {
		fontSize: '1rem', // 16px
		fontWeight: 'normal',
		fontFamily: 'Roboto',
	},
	body2: {
		fontSize: '0.875rem', // 14px
		fontWeight: 'normal',
		fontFamily: 'Roboto',
	},
	button: {
		fontSize: '0.875rem', // 14px
		textTransform: 'none',
		fontWeight: 'medium',
	},
	caption: {
		fontSize: '0.75rem', // 12px
		fontWeight: 'normal',
	},
	overline: {
		fontSize: '0.75rem', // 12px
		fontWeight: 'medium',
		textTransform: 'uppercase',
	},
}

const lightThemePalette: CustomPaletteOptions = {
	mode: 'light',
	primary: {
		main: PRIMARY_BASE_COLOR,
		light: '#3f90bd',
		dark: darken(PRIMARY_BASE_COLOR, 0.1),
		contrastText: '#FCFCFC',
		50: '#0fa9e9',
		60: '#099bda',
		70: '#005b85',
		80: '#0077b3',
		90: '#005792',
		100: '#001b28',
	},
	secondary: {
		main: SECONDARY_BASE_COLOR,
		contrastText: '#0A0A0A',
		dark: darken(SECONDARY_BASE_COLOR, 0.1),
		light: alpha(SECONDARY_BASE_COLOR, 0.1),
		60: '#7eb637',
		70: '#69a22d',
		80: '#558e24',
		90: '#316c11',
		100: '#1c280d',
	},
	common: {
		darkGrey: '#8A8A8A',
		grayscaleBase: '#eceef0',
		green: '#5E842A',
		red: '#E70606',
		blue: '#0088C7',
		app_gradient: 'linear-gradient(81.27deg, #AE5DED 6.66%, #46C8D9 93.34%)',
	},
	info: {
		main: '#06a6d7',
		light: '#cdedf7',
		dark: darken('#06a6d7', 0.1),
	},
	success: {
		main: '#5faf26',
		light: '#5faf26',
		dark: darken('#5faf26', 0.1),
	},
	error: {
		main: '#c63030',
		contrastText: '#FCFCFC',
		dark: darken('#c63030', 0.1),
		light: '#c63030',
		10: '#f4d6d6',
		20: '#ecbaba',
		30: '#e39898',
		40: '#d97575',
		50: '#d05353',
		60: '#a52828',
		70: '#842020',
		80: '#631818',
		90: '#421010',
		100: '#280a0a',
	},
	warning: {
		main: '#fbb315',
		light: '#fbb315',
		dark: darken('#fbb315', 0.1),
	},
	text: {
		primary: '#000000',
	},
	background: {
		default: '#FCFCFC',
		paper: '#F6F7F8',
		primaryGradient: 'linear-gradient(to bottom, #183F6E, #192432, #1F2935)',
		blueGradient: 'linear-gradient(to right, #0090C4, #004887)',
		lightGreyGradient: 'linear-gradient(80.38deg, #FCFCFC 0%, #ECEEF0 51.67%, #FCFCFC 99.37%)',
	},
	grey: {
		50: GREY_BASE_COLOR, // Base
		100: '#ced6da',
		200: '#afbac2',
		300: '#8f9fab',
		400: '#778a98',
		500: '#5f7687',
		600: '#536876',
		700: '#435460',
		800: '#35424b',
		900: DEFAULT_BASE_MAIN,
	},
	default: {
		main: DEFAULT_BASE_MAIN,
		dark: darken(DEFAULT_BASE_MAIN, 0.1),
		light: alpha(DEFAULT_BASE_MAIN, 0.1),
		contrastText: '#FCFCFC',
	},
}

const darkThemePalette: CustomPaletteOptions = {
	mode: 'dark',
	primary: {
		main: PRIMARY_BASE_COLOR,
		light: '#C2F4FF',
		dark: darken(PRIMARY_BASE_COLOR, 0.1),
		contrastText: '#FCFCFC',
		50: '#62d0ff',
		60: '#4ec4ff',
		70: '#33b7ff',
		80: '#0fa9e9',
		90: '#099bda',
		100: '#001b28',
	},
	secondary: {
		main: SECONDARY_BASE_COLOR,
		contrastText: '#FCFCFC',
		dark: darken(SECONDARY_BASE_COLOR, 0.1),
		light: alpha(SECONDARY_BASE_COLOR, 0.1),
		60: '#558e24',
		70: '#316c11',
		80: '#1c280d',
		90: '#14200e',
		100: '#141f12',
	},
	common: {
		darkGrey: '#9e9e9e',
		grayscaleBase: '#2e2e2e',
		green: '#66bb6a',
		red: '#E70606',
		blue: '#0088C7',
		app_gradient: 'linear-gradient(81.27deg, #512da8 6.66%, #1976d2 93.34%)',
	},
	info: {
		main: '#29b6f6',
		light: '#e1f5fe',
		dark: '#0288d1',
	},
	error: {
		main: '#c63030',
		contrastText: '#FCFCFC',
		dark: darken('#c63030', 0.1),
		light: alpha('#c63030', 0.1),
		10: '#f4d6d6',
		20: '#ecbaba',
		30: '#e39898',
		40: '#d97575',
		50: '#d05353',
		60: '#a52828',
		70: '#842020',
		80: '#631818',
		90: '#421010',
		100: '#280a0a',
	},
	success: {
		main: '#66bb6a',
		light: '#e8f5e9',
		dark: '#388e3c',
	},
	warning: {
		main: '#ffb74d',
		light: '#fff3e0',
		dark: '#f57c00',
	},
	text: {
		primary: '#E0E0E0',
		secondary: '#B0B0B0',
		disabled: '#757575',
	},
	background: {
		default: '#121212',
		paper: '#1d1d1d',
		primaryGradient: 'linear-gradient(to bottom, #0d47a1, #1a237e, #1c2b36)',
		blueGradient: 'linear-gradient(to right, #42a5f5, #1e88e5)',
		lightGreyGradient: 'linear-gradient(80.38deg, #333333 0%, #2c2c2c 51.67%, #333333 99.37%)',
	},
	grey: {
		50: '#424242',
		100: '#373737',
		200: '#2c2c2c',
		300: '#252525',
		400: '#1d1d1d',
		500: '#171717',
		600: '#121212',
		700: '#0d0d0d',
		800: '#0a0a0a',
		900: '#050505',
	},
	default: {
		main: DEFAULT_BASE_MAIN,
		dark: darken(DEFAULT_BASE_MAIN, 0.1),
		light: alpha(DEFAULT_BASE_MAIN, 0.1),
		contrastText: '#FCFCFC',
	},
}

const components: ThemeOptions['components'] = {
	MuiCssBaseline: {
		styleOverrides: (themeParam) => ({
			body: {
				'*::-webkit-scrollbar': {
					width: '3px',
				},
				'*::-webkit-scrollbar-track': {
					'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
				},
				'*::-webkit-scrollbar-thumb': {
					backgroundColor: themeParam.palette.grey[700],
				},
				'::-webkit-scrollbar': {
					width: '3px',
				},
				'::-webkit-scrollbar-track': {
					'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: themeParam.palette.grey[700],
				},
			},
		}),
	},
	MuiButton: {
		styleOverrides: {
			root: () => ({
				textTransform: 'inherit',
				borderRadius: 4,
				height: 38,
				fontFamily: 'Roboto',
				fontWeight: 500,
				fontSize: '1rem',
			}),
		},
		variants: [
			{
				props: { variant: 'contained' },
				style: ({ theme, ...props }) => {
					const { ownerState, children } = props as { ownerState: { color: T_BUTTON_COLOR }; children: React.ReactNode }
					return {
						backgroundColor: theme.palette[ownerState.color].main,
						color: theme.palette.grey[50],
						':hover': {
							backgroundColor: theme.palette.grey[50],
							color: 'inherit',
						},
						...(!buttonHasText(children)
							? { minWidth: 40, height: 32, '.MuiButton-startIcon': { margin: 0, svg: { width: 16 } } }
							: { '.MuiButton-endIcon': { svg: { width: 20 } } }),
					}
				},
			},
			{
				props: { variant: 'outlined' },

				style: ({ theme, ...props }) => {
					const { ownerState, children } = props as { ownerState: { color: T_BUTTON_COLOR }; children: React.ReactNode }
					return {
						':hover': {
							backgroundColor: theme.palette[ownerState.color].main,
							color: theme.palette.grey[50],
						},
						...(!buttonHasText(children)
							? { minWidth: 40, height: 32, '.MuiButton-startIcon': { margin: 0, svg: { width: 16 } } }
							: { '.MuiButton-endIcon': { svg: { width: 20 } } }),
					}
				},
			},
			{
				props: { variant: 'text' },
				style: ({ theme, ...props }) => {
					const { ownerState } = props as { ownerState: { color: T_BUTTON_COLOR } }
					return {
						':hover': {
							backgroundColor: theme.palette[ownerState.color].main,
							color: theme.palette.grey[50],
						},
					}
				},
			},
		],
	},
	MuiTab: {
		styleOverrides: {
			root: {
				textTransform: 'none',
				alignItems: 'start',
			},
		},
	},
	MuiTooltip: {
		styleOverrides: {
			tooltip: ({ theme }) => ({
				backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[100],
				color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
			}),
		},
	},
	MuiChip: {
		styleOverrides: {
			root: ({ theme }) => ({
				backgroundColor: 'transparent',
				borderWidth: 1,
				borderRadius: 6,
				borderColor: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[200],
				borderStyle: 'solid',
			}),
		},
	},
}

const lightTheme = (): Theme => {
	let theme = createTheme({
		palette: lightThemePalette as PaletteOptions,
		typography,
		components,
	})
	theme = responsiveFontSizes(theme)
	return theme
}

const darkTheme = (): Theme => {
	let theme = createTheme({
		palette: darkThemePalette as PaletteOptions,
		typography,
		components,
	})
	theme = responsiveFontSizes(theme)
	return theme
}

export default (mode: 'light' | 'dark' = 'light'): ThemeOptions => (mode === 'light' ? lightTheme() : darkTheme())
