import { SIDE_BAR_MENU_ITEM_BORDER_RADIUS } from '@Constants/commons'
import { PRIVATE_ROUTES } from '@Constants/routes'
import { useSideBarPage } from '@Hooks/SideBar/useSideBarPage'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import {
	IconBolt,
	IconChevronDown,
	IconChevronUp,
	IconCompass,
	IconDotsVertical,
	IconTrash,
	IconTrashFilled,
} from '@tabler/icons-react'
import { isEmpty } from 'lodash'
import React from 'react'

const RecentItem: React.FC<RecentItemProps> = ({ title, icon, isActive, onClick, onActionClick, dataCy }) => {
	const theme = useTheme()

	return (
		<ListItemButton
			onClick={onClick}
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				position: 'relative',
				ml: 1,
				paddingY: 0.5,
				mt: 0.1,
				borderRadius: SIDE_BAR_MENU_ITEM_BORDER_RADIUS,
				backgroundColor: isActive ? 'grey.100' : 'transparent',
				color: isActive ? 'primary.80' : 'inherit',
				':hover .menu-button': { visibility: 'visible' },
				':hover .list-item-text': { width: 'calc(100% - 40px)' },
				':hover': {
					cursor: 'pointer',
					background: theme.palette.grey[100],
				},
			}}
			data-cy={dataCy}
		>
			<Box mt={1 / 2}>{icon}</Box>
			<ListItemText
				primary={title}
				primaryTypographyProps={{
					noWrap: true,
					className: 'list-item-text',
					variant: 'body2',
					sx: {
						width: '100%',
						transition: 'width 0.1s ease',
						ml: 1,
					},
				}}
			/>
			<IconButton
				className="menu-button"
				sx={{ visibility: 'hidden', position: 'absolute', right: 0 }}
				onClick={onActionClick}
				data-cy="item-menu-button"
			>
				<IconDotsVertical />
			</IconButton>
		</ListItemButton>
	)
}

const RecentSection: React.FC<RecentSectionProps> = ({
	title,
	isOpen,
	onToggle,
	items,
	isError,
	isLoading,
	icon,
	routePrefix,
	onItemClick,
	onActionClick,
}) => {
	const theme = useTheme()

	return (
		<Box px={1}>
			<List sx={{ ml: -2 }}>
				<ListItemButton
					sx={{
						borderRadius: SIDE_BAR_MENU_ITEM_BORDER_RADIUS,
						':hover': {
							cursor: 'pointer',
							background: theme.palette.grey[100],
						},
					}}
					onClick={onToggle}
					data-cy={`collapse-item-${title.toLowerCase().replace(' ', '-')}`}
				>
					<ListItemText
						data-cy={`collapse-item-title-${title.toLowerCase().replace(' ', '-')}`}
						primary={
							<Typography variant="body2" fontWeight={'500'}>
								{title}
							</Typography>
						}
					/>
					{isOpen ? <IconChevronUp /> : <IconChevronDown />}
				</ListItemButton>
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					{isLoading && (
						<Typography variant="subtitle2" fontWeight={'500'} ml={3}>
							Loading...
						</Typography>
					)}
					{isError && !isLoading && (
						<Typography variant="subtitle2" ml={3}>
							{'Something went wrong!'}
						</Typography>
					)}
					{items.map((item) => (
						<RecentItem
							dataCy={`recent-item-${title.toLowerCase().replace(' ', '-')}`}
							key={item.id}
							id={item.id}
							title={item.title}
							icon={icon}
							isActive={window.location.pathname === `${routePrefix.replace(':id?', item.id)}`}
							onClick={() => onItemClick(item.id)}
							onActionClick={(event) => {
								event.stopPropagation()
								onActionClick(item.id, event)
							}}
						/>
					))}
					{!isLoading && !isError && isEmpty(items) && (
						<Typography variant="subtitle2" ml={4} color={'grey.300'}>
							No item available.
						</Typography>
					)}
				</Collapse>
			</List>
		</Box>
	)
}

export const RecentConversations: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
	const {
		conversations,
		isError,
		isLoading,
		handleRecentItemClick,
		anchorEl,
		handleMenuClose,
		handleDelete,
		isOpenKnowledge,
		isOpenTasks,
		handleClickCollapse,
		tasksList,
		isTasksListError,
		isTasksListLoading,
		tasksListError,
		handleDeleteTask,
		handleActionIcon,
		handleDeleteConfirmationPopup,
	} = useSideBarPage()
	const theme = useTheme()

	const handleActionIconPage = (item: string, target: 'knowledge' | 'tasks') => (e: React.MouseEvent<HTMLElement>) => {
		handleActionIcon(e, {
			target,
			actions: (
				<Button
					data-cy="delete-conversation-menu-item"
					color="error"
					variant="contained"
					onClick={() => (target === 'tasks' ? handleDeleteTask(item) : handleDelete(item))}
					endIcon={<IconTrash />}
				>
					{'Delete'}
				</Button>
			),
		})
	}

	if (!isOpen) return null
	return (
		<Box py={2} pl={2} data-cy="recent-conversations">
			<Typography variant="body2" fontWeight={'700'} color="primary.main">
				Recently
			</Typography>
			<RecentSection
				title="Knowledge Discovery"
				isOpen={isOpenKnowledge}
				onToggle={() => handleClickCollapse('knowledge')}
				items={conversations}
				isError={isError}
				isLoading={isLoading}
				icon={<IconCompass color={theme.palette.primary['90']} size={16} />}
				routePrefix={PRIVATE_ROUTES.conversationId}
				onItemClick={(id) => handleRecentItemClick(id, 'conversation')}
				onActionClick={(id, event) => handleActionIconPage(id, 'knowledge')(event)}
			/>
			<RecentSection
				title="Task Assistant"
				isOpen={isOpenTasks}
				onToggle={() => handleClickCollapse('tasks')}
				items={tasksList}
				isError={isTasksListError}
				isLoading={isTasksListLoading}
				errorMessage={tasksListError?.message}
				icon={<IconBolt color={theme.palette.common.green} size={16} />}
				routePrefix={PRIVATE_ROUTES.taskSessionId}
				onItemClick={(id) => handleRecentItemClick(id, 'task')}
				onActionClick={(id, event) => handleActionIconPage(id, 'tasks')(event)}
			/>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem
					onClick={handleDeleteConfirmationPopup}
					sx={{ display: 'flex', justifyContent: 'space-between', width: 150, height: 30 }}
				>
					<Typography variant="body2">Delete</Typography>
					<IconTrashFilled />
				</MenuItem>
			</Menu>
		</Box>
	)
}
