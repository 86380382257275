import { Stack, Typography } from '@mui/material'

const DKMLogo = ({ showText = true }: DKMLogoComponentProps) => {
	return (
		<Stack direction={'row'} spacing={2}>
			<img alt="ADB_logo" src="/images/ADB_small_logo.png" width={48} />
			{showText && (
				<Stack justifyContent={'center'}>
					<Typography variant={'body1'} fontWeight={700} color={'primary.100'}>
						{'NAVIGATOR'}
					</Typography>
				</Stack>
			)}
		</Stack>
	)
}

export default DKMLogo
