import { MOBILE_APP_BAR_HEIGHT } from '@Constants/commons'
import PrivateLayout from '@Layouts/PrivateLayout'
import { msalLogout } from '@Libs/msalToken'
import { Button, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

const BUTTON_WIDTH = 150
const DKMGlobalError = ({ isErrorBoundary = false }: IDKMGlobalErrorComponentProps) => {
	const navigate = useNavigate()
	const theme: Theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const { resetBoundary } = useErrorBoundary()

	const handleRetryButtonClick = () => {
		if (isErrorBoundary) {
			resetBoundary()
			return
		}
		navigate(0)
	}

	return (
		<PrivateLayout disableSideBar>
			<Stack sx={{ minHeight: `calc(100vh - ${isMobile ? MOBILE_APP_BAR_HEIGHT : 0}px)` }}>
				<Stack flex={1} px={{ xs: 2, sm: 5, md: 15, lg: 25 }} py={2} alignItems={'center'} justifyContent={'center'}>
					<img alt="empty_data_logo" src={'/images/error.png'} width={'50%'} />
					<Typography textAlign={'center'} variant={'h2Bold'}>
						{'Oops, that didn’t go as expected.'}
					</Typography>
					<Typography textAlign={'center'} variant={'subtitle1'}>
						{"We're on it, and things will be back to normal soon."}
					</Typography>
					<Stack mt={2} spacing={2} direction={'row'}>
						<Button sx={{ width: BUTTON_WIDTH }} variant={'outlined'} color={'default'} onClick={msalLogout}>
							Log out
						</Button>
						<Button sx={{ width: BUTTON_WIDTH }} variant={'contained'} onClick={handleRetryButtonClick}>
							Retry
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</PrivateLayout>
	)
}

export default DKMGlobalError
