import { AlertColor } from '@mui/material'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
interface SnackbarState {
	key: number | undefined
	isOpen: boolean
	severity: AlertColor
	message: string
	openToast: (arg1: AlertColor, arg2: string) => void
	closeToast: () => void
}
export const useSnackbar = create<SnackbarState>()(
	persist(
		(set) => ({
			key: undefined,
			isOpen: false,
			severity: 'info',
			message: '',
			openToast: (severity, message) =>
				set({ isOpen: true, severity: severity, message: message, key: new Date().getTime() }),
			closeToast: () => set({ isOpen: false, key: undefined }),
		}),
		{ name: 'alertStore', storage: createJSONStorage(() => sessionStorage) }
	)
)
