import { IconButton, Slide, SlideProps } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import { IconX } from '@tabler/icons-react'
import React from 'react'
import { SNACK_BAR_TOAST_AUTO_HIDE_DURATION } from '../../Constants/commons'
import { getCspNonce } from '../../Libs/utilities'

const StyledAlert = styled(Alert)<AlertProps>(({ theme, severity }) => ({
	backgroundColor: theme.palette.common.white,
	boxShadow: theme.shadows[24],
	color: theme.palette[severity ?? 'success'].main,
	borderRadius: 8,
}))

const SlideTransition = (props: SlideProps) => {
	return <Slide {...props} direction="up" />
}

const SnackbarComponent = ({ key, isOpen, message, severity, closeAlert }: ISnackbarComponentProps) => {
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}
		closeAlert()
	}
	return (
		<Snackbar
			key={key}
			data-cy={'snackbar'}
			open={isOpen}
			autoHideDuration={SNACK_BAR_TOAST_AUTO_HIDE_DURATION}
			sx={{ borderRadius: 2 }}
			onClose={handleClose}
			TransitionComponent={SlideTransition}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		>
			<StyledAlert
				data-cy={'snackbar-alert'}
				severity={severity}
				sx={{
					flexDirection: 'row-reverse',
					alignItems: 'center',
					'.MuiAlert-icon': {
						m: 0,
						p: 0,
						borderTopRightRadius: 6,
						borderBottomRightRadius: 6,
						backgroundColor: `${severity}.main`,
					},
					p: 0,
					'.MuiAlert-message': {
						p: 0,
						px: 2,
					},
				}}
				variant="outlined"
				icon={
					<IconButton data-cy="custom-icon" onClick={handleClose} sx={{ color: 'common.white' }}>
						<IconX />
					</IconButton>
				}
				nonce={getCspNonce()}
			>
				{message}
			</StyledAlert>
		</Snackbar>
	)
}
export default SnackbarComponent
