import PrivateLayout from '@Layouts/PrivateLayout'
import { Stack, Typography } from '@mui/material'
import { ReactComponent as NoNetworkSVG } from './no_network.svg'

const NoNetwork = () => {
	return (
		<PrivateLayout>
			<Stack alignItems={'center'} spacing={1}>
				<NoNetworkSVG />
				<Typography textAlign={'center'} variant="h5Bold">
					{'No internet connection.'}
				</Typography>
				<Typography textAlign={'center'} variant="h5Bold">
					{'Check your connection and retry.'}
				</Typography>
			</Stack>
		</PrivateLayout>
	)
}

export default NoNetwork
