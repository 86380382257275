import { SIDE_BAR_MENU_ITEM_BORDER_RADIUS } from '@Constants/commons'
import { PRIVATE_ROUTES, ROUTE_TITLE } from '@Constants/routes'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconBolt, IconCompass, IconFolders, IconHeart } from '@tabler/icons-react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface NavigationItemProps {
	isOpen: boolean
	isActive: boolean
	to: string
	icon: React.ReactNode
	label: string
	dataCy: string
}

const NavigationItem: React.FC<NavigationItemProps> = ({ dataCy, isOpen, isActive, to, icon, label }) => {
	const theme = useTheme()

	return (
		<ListItem data-cy={dataCy} disablePadding={!isOpen} sx={{ p: 0.5 }}>
			<Tooltip title={label} placement="right" disableHoverListener={isOpen}>
				<ListItemButton
					component={Link}
					to={to}
					sx={{
						minHeight: 48,
						justifyContent: isOpen ? 'initial' : 'center',
						px: 2.5,
						backgroundColor: isActive ? 'grey.100' : 'transparent',
						color: isActive ? theme.palette.primary['80'] : 'inherit',
						borderRadius: SIDE_BAR_MENU_ITEM_BORDER_RADIUS,
						':hover': {
							cursor: 'pointer',
							background: theme.palette.grey[100],
						},
					}}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: isOpen ? 3 : 'auto',
							justifyContent: 'center',
							color: isActive ? theme.palette.primary['80'] : 'inherit',
						}}
					>
						{icon}
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography variant="body2" fontWeight={'500'}>
								{label}
							</Typography>
						}
						sx={{
							opacity: isOpen ? 1 : 0,
							color: label === 'Task Assistant' ? theme.palette.common.green : 'inherit',
						}}
					/>
				</ListItemButton>
			</Tooltip>
		</ListItem>
	)
}

interface NavigationItemsProps {
	isOpen: boolean
}

export const NavigationItems: React.FC<NavigationItemsProps> = ({ isOpen }) => {
	const location = useLocation()
	const theme = useTheme()

	const navItems = [
		{
			to: PRIVATE_ROUTES.knowledge,
			icon: <IconCompass stroke={2} />,
			label: ROUTE_TITLE[PRIVATE_ROUTES.knowledge],
			dataCy: 'nav-item-knowledge',
		},
		{
			to: PRIVATE_ROUTES.task,
			icon: <IconBolt style={{ color: theme.palette.common.green }} />,
			label: ROUTE_TITLE[PRIVATE_ROUTES.task],
			dataCy: 'nav-item-task',
		},
		{
			to: PRIVATE_ROUTES.workspace,
			icon: <IconFolders stroke={2} />,
			label: ROUTE_TITLE[PRIVATE_ROUTES.workspace],
			dataCy: 'nav-item-workspace',
		},
		{
			to: PRIVATE_ROUTES.favorites,
			icon: <IconHeart stroke={2} />,
			label: ROUTE_TITLE[PRIVATE_ROUTES.favorites],
			dataCy: 'nav-item-favourites',
		},
	]

	return (
		<List>
			{navItems.map((item, itemIndex) => (
				<Box mt={!isOpen && itemIndex > 0 ? 1 : 0} px={!isOpen ? 1 : 0} key={item.to}>
					<NavigationItem isOpen={isOpen} isActive={location.pathname.includes(item.to)} {...item} />
				</Box>
			))}
		</List>
	)
}
