import { FETCH_USER_DETAILS } from '@Constants/query-keys'
import { getUserDetails } from '@Requests/graph.api'
import { useQuery } from '@tanstack/react-query'
export const useGraphUserDetails = (token: string | null) => {
	return useQuery({
		queryKey: [FETCH_USER_DETAILS],
		queryFn: async () => {
			if (!token) throw new Error('No token available')
			return await getUserDetails(token)
		},
		enabled: !!token,
		select: ({ data }) => data,
	})
}
