import { useSideBarPage } from '@Hooks/SideBar/useSideBarPage'
import { getInitials } from '@Libs/utilities'
import { AppBar, Avatar, IconButton, Menu, Toolbar, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconMenuDeep } from '@tabler/icons-react'
import React, { useState } from 'react'
import ProfileMenu from './ProfileMenu'

interface MobileAppBarProps {
	onMenuClick: () => void
	name: string
	avatarUrl: string
	title: string
}

const MobileAppBar: React.FC<MobileAppBarProps> = ({ onMenuClick, name, avatarUrl, title: roleTitle }) => {
	const { title, elevation } = useSideBarPage()
	const theme = useTheme()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleProfileMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<AppBar
			position="fixed"
			elevation={elevation}
			color="transparent"
			data-cy="mobile-appbar"
			sx={{
				backgroundColor: 'background.default',
				transition: theme.transitions.create(['background-color', 'box-shadow'], {
					duration: theme.transitions.duration.short,
				}),
			}}
		>
			<Toolbar>
				<IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick} data-cy="mobile-menu-toggle">
					<IconMenuDeep />
				</IconButton>
				<Typography variant="h6Bold" sx={{ flexGrow: 1, textAlign: 'center' }}>
					{title}
				</Typography>
				<IconButton color="inherit" edge={'end'} onClick={handleProfileMenuOpen}>
					<Avatar alt="Profile" src={avatarUrl} sx={{ backgroundColor: 'primary.main' }}>
						<Typography variant="h6">{getInitials(name)}</Typography>
					</Avatar>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleProfileMenuClose}
					sx={{
						mt: 1.5,
						'& .MuiPaper-root': {
							overflow: 'visible',
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<ProfileMenu name={name} role={roleTitle} />
				</Menu>
			</Toolbar>
		</AppBar>
	)
}

export default MobileAppBar
