'use client'

import DKMGlobalError from '@Shared/DKMGlobalError'
import { ErrorInfo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const DKMErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const handleError = (error: Error, info: ErrorInfo) => {
		console.error('Error caught by Error Boundary:', error, info)
	}

	return (
		<ErrorBoundary fallback={<DKMGlobalError isErrorBoundary />} onError={handleError}>
			{children}
		</ErrorBoundary>
	)
}

export default DKMErrorBoundary
