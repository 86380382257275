import { getCspNonce } from '@Libs/utilities'
import { Box, Typography } from '@mui/material'
import { keyframes, styled } from '@mui/system'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'

// Keyframe animation for AI theme (pulsing effect)
const pulse = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.6; }
  100% { transform: scale(1); opacity: 1; }
`

// Styled component for the loader container
const LoaderContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
	width: '100vw',
	background: 'radial-gradient(circle, rgb(142, 198, 63, 0.3) 0%, rgb(174, 93, 237, 0.5) 100%)',
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 1300,
})

const IconWrapper = styled(motion.div)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '150px',
	height: '150px',
	marginBottom: '20px',
	border: '4px solid',
	borderImageSource: 'linear-gradient(81.27deg, #ae5ded 6.66%, #46c8d9 93.34%)',
	borderImageSlice: 1,
	animation: `${pulse} 2s infinite`,
})

// List of SVGs
const svgIcons = [
	{
		key: 'bolt',
		svg: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				viewBox="0 0 24 24"
				fill="none"
				stroke="rgb(94, 132, 42)"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="icon icon-tabler icon-tabler-bolt"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
			</svg>
		),
	},
	{
		key: 'compass',
		svg: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#0077b3"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="icon icon-tabler icon-tabler-compass"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M8 16l2 -6l6 -2l-2 6l-6 2" />
				<path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
				<path d="M12 3l0 2" />
				<path d="M12 19l0 2" />
				<path d="M3 12l2 0" />
				<path d="M19 12l2 0" />
			</svg>
		),
	},
	{
		key: 'folder',
		svg: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#ae5ded"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="icon icon-tabler icon-tabler-folder"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
			</svg>
		),
	},
	{
		key: 'dashboard',
		svg: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#46c8d9"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="icon icon-tabler icon-tabler-layout-dashboard"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M5 4h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1" />
				<path d="M5 16h4a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" />
				<path d="M15 12h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1" />
				<path d="M15 4h4a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" />
			</svg>
		),
	},
]

const FullScreenLoader: React.FC<{ text?: string }> = ({ text = 'Loading...' }) => {
	const [currentIconIndex, setCurrentIconIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIconIndex((prevIndex) => (prevIndex + 1) % svgIcons.length)
		}, 1000) // Change SVG every 1 second

		return () => clearInterval(interval)
	}, [])
	return (
		<LoaderContainer nonce={getCspNonce()}>
			<Box textAlign="center">
				<IconWrapper
					initial={{ opacity: 0, scale: 0.8 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.8 }}
					nonce={getCspNonce()}
				>
					{svgIcons[currentIconIndex].svg}
				</IconWrapper>
				<Typography variant="h5" color="primary" sx={{ mt: 2 }}>
					{text}
				</Typography>
			</Box>
		</LoaderContainer>
	)
}

export default FullScreenLoader
