import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const useSessionCountStore = create<ISessionCountStore>()(
	persist(
		(set) => ({
			knowledgeDiscoverySessionCount: 0,
			superChargeTaskSessionCount: 0,
			updateKnowledgeDiscoverySessionCount: (knowledgeDiscoverySessionCount: number) =>
				set({ knowledgeDiscoverySessionCount }),
			updateSuperChargeTaskSessionCount: (superChargeTaskSessionCount: number) => set({ superChargeTaskSessionCount }),
			resetSessionCountStore: () => set({ knowledgeDiscoverySessionCount: 0, superChargeTaskSessionCount: 0 }),
		}),
		{ name: 'sessionCountStore', storage: createJSONStorage(() => sessionStorage) }
	)
)
