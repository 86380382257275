import { DRAWER_WIDTH, MOBILE_MAIN_CONTENT_TOP_SPACE } from '@Constants/commons'
import { PRIVATE_ROUTES } from '@Constants/routes'
import useToggleSidebar from '@Hooks/useToggleSidebar'
import { getCspNonce } from '@Libs/utilities'
import DKMDrawer from '@Shared/Sidebar'
import { useUser } from '@Store/user'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const Main = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
	open?: boolean
}>(({ theme, open = false }) => ({
	flexGrow: 1,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: open ? DRAWER_WIDTH : theme.spacing(8),
	[theme.breakpoints.down('sm')]: {
		marginLeft: 0,
		marginTop: MOBILE_MAIN_CONTENT_TOP_SPACE * 8,
	},
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const PrivateLayout: React.FC<{ children?: React.ReactNode; disableSideBar?: boolean }> = ({
	children,
	disableSideBar = false,
}) => {
	const { handleToggleSidebar, isOpen } = useToggleSidebar()
	const { user } = useUser()

	// Task related path shouldn't be allowed for mobile devices start
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()
	useEffect(() => {
		if (isMobile && window.location.pathname.includes(PRIVATE_ROUTES.task)) {
			navigate(PRIVATE_ROUTES.knowledge)
		}
	}, [])
	// Task related path shouldn't be allowed for mobile devices end

	return (
		<Box data-cy="private-layout">
			<Box data-cy="sidebar">
				<DKMDrawer
					isOpen={isOpen}
					onToggleSidebar={handleToggleSidebar}
					name={user?.name || ''}
					avatarUrl={user?.photo || ''}
					title={user?.role || ''}
					disableSideBar={disableSideBar}
				/>
			</Box>
			<Main
				data-cy="main-content"
				nonce={getCspNonce()}
				sx={{
					flexGrow: 1,
				}}
				open={isOpen}
			>
				{children || <Outlet />}
			</Main>
		</Box>
	)
}

export default PrivateLayout
