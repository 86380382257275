import request from '@Config/request'
import { FEEDBACK_TYPE, SECURE_STORAGE_KEY } from '@Constants/commons'
import secureLocalStorage from 'react-secure-storage'
import { fetchAIStreamingResponse } from './stream.api'

export const fileUpload = async (formData: FormData) => {
	const { data, status, statusText } = await request.post<ITaskFileUploadResponse>(
		'/tasks/v1/tasks/token-count',
		formData,
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		}
	)
	return {
		data,
		status,
		statusText,
	}
}

export const fetchTaskStreamingResponse = async (payload: ITaskSessionGenerationRequest) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tasks/v1/tasks/generate`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${secureLocalStorage.getItem(SECURE_STORAGE_KEY.JWT)}`,
			},
		})
		if (!response.ok) {
			const error = { status: response.status, statusText: response.statusText, message: response.statusText }
			throw error
		}

		if (!response.body?.getReader) {
			throw new Error('ReadableStream not supported')
		}
		return response.body
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		throw new Error(`${e.status}`)
	}
}

export const fetchTaskSessionFollowUpChatStreamingResponse = async (
	payload: ITaskSessionFollowUpChatGenerationRequest,
	signal: AbortSignal
) => {
	return fetchAIStreamingResponse('/tasks/v1/tasks/chat', payload, signal)
}

export const deleteUploadedFile = async (collectionId: string, fileName: string) => {
	const { data, status, statusText } = await request.delete(`/tasks/v1/tasks/token-count/${collectionId}/${fileName}`)
	return {
		data,
		status,
		statusText,
	}
}

export const getKeywordsForTaskSessionContent = async (taskSessionId: string, taskSessionContentId: string) => {
	const { data, status, statusText } = await request.get<IConversationHistoryResponse>(
		`/tasks/v1/tasks/${taskSessionId}/keywords?message_id=${taskSessionContentId}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const deleteKeywordForTaskSessionContent = async (
	taskSessionId: string,
	taskSessionContentId: string,
	keyword: string
) => {
	const { data, status, statusText } = await request.delete(
		`/tasks/v1/tasks/${taskSessionId}/keywords?message_id=${taskSessionContentId}&keyword=${keyword}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const fetchTaskSessions = async (size: number, page: number, title = '') => {
	const { data, status, statusText } = await request.post<ISessionsResponse>(`/tasks/v1/tasks`, {
		...{ size, page },
		...(title ? { title } : {}),
	})
	return {
		data,
		status,
		statusText,
	}
}

export const getTaskSessionById = async (taskSessionId: string) => {
	const { data, status, statusText } = await request.get<ITaskSessionHistoryResponse>(`/tasks/v1/tasks/${taskSessionId}`)
	return {
		data,
		status,
		statusText,
	}
}

export const deleteTasksById = async (taskId: string) => {
	const { data, status, statusText } = await request.delete(`/tasks/v1/tasks/${taskId}`)
	return {
		data,
		status,
		statusText,
	}
}

export const getTaskInputExpertiseAndSkillsTOR = async (searchText: string) => {
	const { data, status, statusText } = await request.get<AutocompleteOption[]>(
		`/tasks/v1/tasks/skills?search=${searchText}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const fetchTaskTORStreamingResponse = async (payload: ITaskTORSessionGenerationRequest, signal: AbortSignal) => {
	return fetchAIStreamingResponse('/tasks/v1/tasks/generate/tor', payload, signal)
}

export const getTaskActiveQuestionsFeedback = async (feedbackType: FEEDBACK_TYPE) => {
	const { data, status, statusText } = await request.get<IActiveQuestionResponse>(
		`/tasks/v1/feedback/question?feedback_type=${feedbackType}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const submitTaskContentFeedback = async (payload: ITaskContentFeedbackPayload) => {
	const { data, status, statusText } = await request.post<string>(`/tasks/v1/feedback/responses/`, payload)
	return {
		data,
		status,
		statusText,
	}
}

export const modifyKeywordsForTaskContent = async (payload: IModifyKeywordsForTaskContentPayload) => {
	const { data, status, statusText } = await request.post<string[]>(`/tasks/v1/tasks/${payload.task_id}/add_keywords`, {
		message_id: payload.message_id,
		final_keywords: payload.keywords,
	})
	return {
		data,
		status,
		statusText,
	}
}

export const saveToSharepoint = async (taskSessionId: string) => {
	const { data, status, statusText } = await request.post<{ sp_download_url: string }>(
		`/tasks/v1/tasks/save/${taskSessionId}`
	)
	return {
		data,
		status,
		statusText,
	}
}

export const downloadFileTaskAssistance = async (fileId: string) => {
	const { data, status, statusText, headers } = await request.get(`/tasks/v1/tasks/download-attachment/${fileId}`, {
		responseType: 'blob',
	})
	return {
		data,
		headers,
		status,
		statusText,
	}
}

export const summarizeTaskAssistanceSessionTitle = async (payload: ITaskTitleSummarizationPayload) => {
	const { data, status, statusText } = await request.post<ITaskTitleSummarizationResponse>(
		`/tasks/v1/tasks/summarize-title`,
		payload
	)
	return {
		data,
		status,
		statusText,
	}
}
