import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest } from '@Config/authConfig'
import { SECURE_STORAGE_KEY } from '@Constants/commons'
import { msalInstance } from 'index'
import secureLocalStorage from 'react-secure-storage'

// Function to acquire a new token
export const acquireNewToken = async () => {
	const account = msalInstance.getAllAccounts()[0]
	if (!account) {
		await msalLogout()
		return
	}
	try {
		const response = await msalInstance.acquireTokenSilent({
			...loginRequest,
			account: account,
		})
		secureLocalStorage.setItem(SECURE_STORAGE_KEY.JWT, response.accessToken)
		return response.accessToken
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			// User interaction is needed, redirect to login
			await msalInstance.acquireTokenRedirect(loginRequest)
		} else {
			await msalLogout()
			return
		}
		throw error
	}
}

export const msalLogout = async () => {
	secureLocalStorage.clear()
	const account = msalInstance.getAllAccounts()[0]
	await msalInstance.logoutRedirect(account ? { account } : undefined)
}
