import { NO_OF_RECENT_CONVERSATION_SHOW, QUERY_KEY } from '@Constants/commons'
import { fetchConversationSessions } from '@Requests/knowledge.api'
import { useQuery } from '@tanstack/react-query'
export const useFetchRecentConversations = () => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_CHAT_RECENT_CONVERSIONS],
		queryFn: () => fetchConversationSessions(NO_OF_RECENT_CONVERSATION_SHOW, 1),
		select: ({ data }) => data,
	})
}
