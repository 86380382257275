import { useQuery } from '@tanstack/react-query'
import queryClient from './../Config/queryClientProvider'
import { SIDEBAR_TOGGLE } from './../Constants/commons'
const useToggleSidebar = () => {
	const { data: isOpen = false } = useQuery<boolean>({
		queryKey: [SIDEBAR_TOGGLE],
		queryFn: () => queryClient.getQueryData<boolean>([SIDEBAR_TOGGLE]) ?? false,
	})

	const handleToggleSidebar = () => {
		queryClient.setQueryData([SIDEBAR_TOGGLE], !isOpen)
	}
	return {
		isOpen,
		handleToggleSidebar,
	}
}

export default useToggleSidebar
