import { getCspNonce } from '../../Libs/utilities'
import { RIGHT_DRAWER_OVERLAY_SIZE } from '../../Constants/commons'
import { Box, Drawer, IconButton, Typography, styled, useTheme } from '@mui/material'
import { IconCircleX } from '@tabler/icons-react'

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}))

const RightDrawerOverlay = ({
	open,
	closeDrawer,
	children,
	title,
	size = RIGHT_DRAWER_OVERLAY_SIZE.LARGE,
}: IRightDrawerOverlayComponent) => {
	const theme = useTheme()
	return (
		<Drawer
			open={open}
			anchor="right"
			onClose={closeDrawer}
			PaperProps={{
				sx: {
					width:
						size === RIGHT_DRAWER_OVERLAY_SIZE.LARGE
							? {
								sm: theme.breakpoints.values.sm,
								md: theme.breakpoints.values.md - 50,
								lg: theme.breakpoints.values.lg - 140,
							}
							: {
								sm: theme.breakpoints.values.sm - 100,
								md: theme.breakpoints.values.md - 400,
								lg: theme.breakpoints.values.lg - 700,
							},
				},
			}}
			sx={{
				'.MuiDrawer-paper': {
					borderLeftStyle: 'solid',
					borderLeftWidth: 10,
					borderLeftColor: 'common.blue',
				},
			}}
		>
			<Box sx={{ px: 2, py: 1 }}>
				<DrawerHeader sx={{ justifyContent: 'space-between', mb: 2 }} nonce={getCspNonce()}>
					<Typography variant="h3Bold">{title}</Typography>
					<IconButton onClick={closeDrawer}>
						<IconCircleX />
					</IconButton>
				</DrawerHeader>
				{children}
			</Box>
		</Drawer>
	)
}

export default RightDrawerOverlay
