import { Box, Typography } from '@mui/material'
import React from 'react'

interface ProfileMenuProps {
	name: string
	role: string
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ name, role }) => {
	return (
		<Box p={2} display="flex" flexDirection="column" alignItems="center">
			<Typography variant="body1" fontWeight="bold">
				{name}
			</Typography>
			<Typography variant="subtitle1Bold" color="secondary.main">
				{role}
			</Typography>
		</Box>
	)
}

export default ProfileMenu
