import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { IconCircleX } from '@tabler/icons-react'
import React from 'react'

const CustomDialog: React.FC<ICustomDialogComponentProps> = ({
	open,
	closeDialog,
	title,
	description,
	body,
	actions,
	maxWidth = 'sm',
	hideCloseIcon = false,
}) => {
	return (
		<Dialog data-cy={'dialog'} open={open} onClose={closeDialog} fullWidth maxWidth={maxWidth}>
			<DialogTitle
				variant="h4Bold"
				fontWeight={450}
				data-cy={'dialog-title'}
				sx={{ borderTopWidth: 10, borderTopStyle: 'solid', borderColor: (theme) => theme.palette.common.blue, pt: 6 }}
			>
				{!hideCloseIcon && (
					<Tooltip title={'Close Dialog'} placement="top">
						<IconButton
							data-cy={'dialog-close-button'}
							sx={{
								position: 'absolute',
								top: 20,
								right: 20,
								svg: {
									rotate: '0deg',
									transition: 'rotate 0.5s ease',
								},
								':hover': {
									svg: {
										transition: 'rotate 0.5s ease',
										rotate: '90deg',
									},
								},
							}}
							onClick={closeDialog}
						>
							<IconCircleX />
						</IconButton>
					</Tooltip>
				)}
				{title}
				<Typography data-cy={'dialog-description'} variant="subtitle2">
					{description}
				</Typography>
			</DialogTitle>
			{body && <DialogContent sx={{ pb: 0 }}>{body}</DialogContent>}
			<DialogActions sx={{ px: 3 }}>{actions}</DialogActions>
		</Dialog>
	)
}

export default CustomDialog
