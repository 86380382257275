import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
interface DialogState extends ICustomDialogComponentProps {
	openDialog: (
		title: string,
		description: string,
		content: { body?: React.ReactNode; actions?: React.ReactNode },
		maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	) => void
}
export const useDialog = create<DialogState>()(
	persist(
		(set) => ({
			open: false,
			title: '',
			description: '',
			maxWidth: 'xs',
			openDialog: (title, description, content, maxWidth = 'sm') =>
				set({ open: true, title, description, maxWidth, body: content.body, actions: content.actions }),
			closeDialog: () => set({ open: false }),
		}),
		{ name: 'alertStore', storage: createJSONStorage(() => sessionStorage) }
	)
)
