import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	supportedLngs: ['en', 'tl'],
	interpolation: {
		escapeValue: false,
	},
	resources: {
		en: {
			translation: `${process.env.PUBLIC_URL}/locales/en/translation.json`,
		},
		fr: {
			translation: `${process.env.PUBLIC_URL}/locales/tl/translation.json`,
		},
	},
})

export default i18n
