import { NO_OF_RECENT_CONVERSATION_SHOW } from '@Constants/commons'
import { FETCH_TASKS_SESSION_LIST } from '@Constants/query-keys'
import { fetchTaskSessions } from '@Requests/task.api'
import { useQuery } from '@tanstack/react-query'
export const useFetchTasksSessionList = () => {
	return useQuery({
		queryKey: [FETCH_TASKS_SESSION_LIST],
		queryFn: () => fetchTaskSessions(NO_OF_RECENT_CONVERSATION_SHOW, 1),
		select: ({ data }) => data,
	})
}
