import { Theme } from '@mui/material/styles'
import { DRAWER_WIDTH } from '@Constants/commons'

export const drawerStyles = {
	openedMixin: (theme: Theme) => ({
		width: DRAWER_WIDTH,
		backgroundColor: 'grey.50',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	}),
	closedMixin: (theme: Theme) => ({
		backgroundColor: 'grey.50',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up('sm')]: {
			width: `calc(${theme.spacing(8)} + 1px)`,
		},
	}),
}

export const listItemStyles = {
	base: (theme: Theme) => ({
		minHeight: 48,
		justifyContent: 'initial',
		px: 2.5,
		borderRadius: 1,
		':hover': {
			cursor: 'pointer',
			background: theme.palette.grey[100],
		},
	}),
	active: (theme: Theme) => ({
		backgroundColor: 'grey.100',
		color: theme.palette.primary['80'],
	}),
}

export const listItemIconStyles = {
	base: {
		minWidth: 0,
		justifyContent: 'center',
	},
	open: {
		mr: 3,
	},
}

export const avatarStyles = {
	base: {
		backgroundColor: 'primary.main',
	},
}

export const footerIconButtonStyles = (theme: Theme) => ({
	':hover': {
		cursor: 'pointer',
		background: theme.palette.grey[100],
	},
})
