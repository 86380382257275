import { RIGHT_DRAWER_OVERLAY_SIZE } from '@Constants/commons'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const useRightDrawerOverlay = create<
	IRightDrawerOverlayComponent & {
		openDrawer: (title: string, children: string | JSX.Element, size?: RIGHT_DRAWER_OVERLAY_SIZE) => void
	}
>()(
	persist(
		(set) => ({
			open: false,
			title: '',
			openDrawer: (title, children, size = RIGHT_DRAWER_OVERLAY_SIZE.LARGE) => set({ open: true, title, children, size }),
			closeDrawer: () => set({ open: false }),
			children: '',
			size: RIGHT_DRAWER_OVERLAY_SIZE.LARGE,
		}),
		{ name: 'rightDrawerOverlayStore', storage: createJSONStorage(() => sessionStorage) }
	)
)
