import { SECURE_STORAGE_KEY, TIMEOUT_API_RETRY_COUNT, TOKEN_EXPIRY_API_RETRY_COUNT } from '@Constants/commons'
import { ERROR_CODE } from '@Constants/errors'
import { acquireNewToken, msalLogout } from '@Libs/msalToken'
import { HttpsAgent } from 'agentkeepalive'
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import secureLocalStorage from 'react-secure-storage'

const httpsAgent = new HttpsAgent({
	keepAlive: true,
	timeout: 60000,
})

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
	_retryCount: number
}

const request = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
	},
	httpAgent: httpsAgent,
	timeout: 60000,
})

// Request interceptor
request.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
		if (csrfToken) {
			config.headers['X-CSRF-Token'] = csrfToken
		}
		config.headers.Authorization = `Bearer ${secureLocalStorage.getItem(SECURE_STORAGE_KEY.JWT)}`
		return config
	},
	(error) => {
		console.log('Axios Request Error : ', error)
		return Promise.reject(new Error(error.message))
	}
)

request.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		console.log('Axios Response Error : ', error)
		// Ensure originalRequest is properly typed
		const originalRequest = error.config as CustomAxiosRequestConfig

		// Set default retry count as 0
		if (!originalRequest?._retryCount) {
			originalRequest._retryCount = 0
		}

		// The request was made but no response was received
		// retry 2 times(count starts from 0)
		if (!error.response && originalRequest._retryCount < TIMEOUT_API_RETRY_COUNT) {
			originalRequest._retryCount++
			return request(originalRequest)
		}

		// Check if the error is due to an expired token (status 401) and retry 1 time(count starts from 0)
		if (
			error.response?.status === ERROR_CODE.UNAUTHORIZED &&
			originalRequest._retryCount < TOKEN_EXPIRY_API_RETRY_COUNT
		) {
			originalRequest._retryCount++
			try {
				const token = await acquireNewToken()
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
				// Retry the original request with the new token
				return request(originalRequest)
			} catch (refreshError) {
				await msalLogout()
				return
			}
		}
		// Check if the error is due to an expired token (status 401) and the request has been retried once(count starts from 0)
		if (
			error.response?.status === ERROR_CODE.UNAUTHORIZED &&
			originalRequest._retryCount === TOKEN_EXPIRY_API_RETRY_COUNT
		) {
			await msalLogout()
			return
		}
		return Promise.reject(error instanceof Error ? error : new Error(String(error)))
	}
)

export default request
